import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_c46a3d30 from './schemes/PassportScheme.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":"/","callback":"/login","user":"/"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/user","method":"get","propertyName":"user"}},"_name":"local"}))

  // password_grant_custom
  $auth.registerStrategy('password_grant_custom', new scheme_c46a3d30($auth, {"client_id":"2","client_secret":"o7ZDA05c5rbU9IsPDX1oIr9VU2XmUg8Dz5HLu6XX","endpoints":{"login":{"url":"/api/webclient/login","method":"post","propertyName":"success.token"},"logout":false,"user":{"url":"api/webclient/user?fields=id,name,email,client_name,birthday&with=activeNotificationsCount,activeVouchersCount"}},"_name":"password_grant_custom"}))

  // laravel_facebook
  $auth.registerStrategy('laravel_facebook', new scheme_23514a38($auth, {"userinfo_endpoint":"https://dev-api.yummy.ro/api/webclient/user?fields=id,name,email,client_name,birthday&with=activeNotificationsCount,activeVouchersCount","authorization_endpoint":"https://dev-api.yummy.ro/oauth/facebook/redirect","redirect_uri":"https://dev.yummy.ro/autentificare","endpoints":{"user":{"url":"api/webclient/user?fields=id,name,email,client_name,birthday&with=activeNotificationsCount,activeVouchersCount"}},"_name":"laravel_facebook"}))

  // laravel_google
  $auth.registerStrategy('laravel_google', new scheme_23514a38($auth, {"userinfo_endpoint":"https://dev-api.yummy.ro/api/webclient/user?fields=id,name,email,client_name,birthday&with=activeNotificationsCount,activeVouchersCount","authorization_endpoint":"https://dev-api.yummy.ro/oauth/google/redirect","redirect_uri":"https://dev.yummy.ro/autentificare","endpoints":{"user":{"url":"api/webclient/user?fields=id,name,email,client_name,birthday&with=activeNotificationsCount,activeVouchersCount"}},"_name":"laravel_google"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
